.content_box {
  padding: 0 16px;
}

.xd-scrm-table-cell:first-child:last-child {
  border-bottom: 0 !important;
}

.table-row-hideline .xd-scrm-table-cell {
  border: none !important;
}

.xd-scrm-tree-select-dropdown .xd-scrm-select-tree .xd-scrm-select-tree-treenode-active .xd-scrm-select-tree-node-content-wrapper {
  outline: none;
}

.protocolModal .xd-scrm-modal-content .xd-scrm-modal-confirm-paragraph {
  max-width: 100%;
}

.ant-message {
  z-index: 2030;
}
